import React, {useContext, useEffect, useState} from "react";
import {MainLayout} from "../../layouts/main-layout/MainLayout";
import {
  Button,
  Box,
  Stack,
  TextField,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {grey} from "@mui/material/colors";
import {IconChevronRight, IconMail, IconPhone} from "@tabler/icons-react";
import {Context as AppContext} from "../../context/AppContext";
import {auth} from "../../config/firebase";
import {ContactForm} from "../../components/forms/ContactForm";

export const RequestImage = () => {
  const [contactForm, setContactForm] = useState({});

  useEffect(() => {
    if (localStorage.getItem("request-form")){
      let latestRequestForm = JSON.parse(localStorage.getItem("request-form") || "") || {};
      if (latestRequestForm) {
        if (latestRequestForm?.message) {
          delete latestRequestForm.message;
        }
        setContactForm({...latestRequestForm});
      }
    } else {
      setContactForm({
        // name: auth?.currentUser?.displayName,
        email: auth?.currentUser?.email
      })
    }

  }, [auth.currentUser])

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    updateForm(key, value);
  }

  const updateForm = (key, value) => {
    setContactForm(prev => ({...prev, [key]: value}))
  }

  const onSubmit = () => {
    if (contactForm) {
      localStorage.setItem("request-form", JSON.stringify(contactForm));
      // TODO submit request image form
    }
  }

  return (
    <MainLayout>

      <Stack alignItems={"center"} justifyContent={"center"} sx={{position: "relative", minHeight: "calc(100dvh - 80px)"}}>
        <Box className={"mission-bg"}
             sx={{position: "absolute", top: 0, left: 0, zIndex: -1, width: "100%", height: "100%"}}/>
        <Box
          sx={{
            position: "absolute", top: 0, left: 0,
            width: "100%", height: "100%", zIndex: -1,
            background: "linear-gradient(260deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.5) 100%), linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 20%)"
          }}/>
        <Grid container sx={{p: 4}}>
          <Grid xs={12} md={7} sx={{m: "auto", height: '100%'}}>
            <Stack maxWidth={"500px"} direction={"column"} alignItems={"center"} justifyContent={"center"} gap={1} sx={{mx: "auto"}}>
              <Typography fontWeight={900} fontSize={26} align={"center"}>
                Get a personalized package for your creative team’s needs
              </Typography>
              <Typography fontWeight={400} fontSize={15} color={grey[600]} align={"center"}>
                Simplify your creative process with 10,000+ images, and share access with your team hassle-free.
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={5}>
            <ContactForm
              title={"Request Image"}
              message={""}
              airtableName={"request image"}
            />
          </Grid>
        </Grid>
      </Stack>

    </MainLayout>
  )
}