import React, {Suspense, useContext, useEffect, useState} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {theme} from "./utils/Theme";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import LoadingScreen from "./components/loading-screen/LoadingScreen";
import {LandingPage} from "./pages/landing-page/LandingPage";
import {onAuthStateChanged} from "firebase/auth"
import {auth} from "./config/firebase";
import {Context as AppContext} from "./context/AppContext";
import {BrowseImages} from "./pages/browse-images/BrowseImages";
import SignInModal from "./layouts/main-layout/SignInModal";
import {Mission} from "./pages/mission/Mission";
import Enterprise from "./pages/enterprise/EnterprisePlasmic";
import {RequestImage} from "./pages/request-image/RequestImage";
import {Pricing} from "./pages/pricing/Pricing";
import {ContactFormModal} from "./components/modals/ContactFormModal";

const Profile = React.lazy(() => import('./pages/profile/Profile'));
const Downloads = React.lazy(() => import('./pages/downloads/Downloads'));
const Collections = React.lazy(() => import('././pages/collections/Collections'));
const Collection = React.lazy(() => import('././pages/collections/Collection'));
const Subscriptions = React.lazy(() => import('././pages/subscriptions/Subscriptions'));

if (process.env.NODE_ENV === 'production') console.log = function () {};

export const App = () => {
  const [isAuth, setIsAuth] = useState(null);
  const {getUserCollections, getUserDetails, getUserOwnedImages, loadImages} = useContext(AppContext)

  onAuthStateChanged(auth, async (user) => {
    setIsAuth(!!user)
  })

  useEffect(() => {
    const fetchImages = async () => {
      await loadImages();
    }
    fetchImages();
  }, [])

  useEffect(() => {
    if (isAuth) {
      const fetchData = async () => {
        await getUserCollections();
        await getUserDetails();
        await getUserOwnedImages();
      }
      fetchData();
    }
  }, [isAuth])

  return (
    <ThemeProvider theme={theme}>
      <SignInModal/>
      <ToastContainer position={"bottom-center"}/>
      <ContactFormModal />
      {isAuth === null ?
        <LoadingScreen/>
        :
        <Suspense fallback={<LoadingScreen/>}>
          {/*<svg data-testid="DirectionsCar"></svg>*/}
          <Routes>
            <Route path={"/"} element={<BrowseImages/>}/>
            <Route exact index path={"/images/:id"} element={<BrowseImages/>}/>
            <Route path={"/images"} exact element={<BrowseImages/>}/>
            <Route path={"/pricing"} exact element={<Pricing/>}/>
            <Route path="/mission" index element={<Mission/>}/>
            <Route path="/enterprise" index element={<Enterprise/>}/>
            <Route path="/request-image" index element={<RequestImage/>}/>
            {
              isAuth &&
              <>
                <Route path="/profile" index element={<Profile/>}/>
                <Route path="/downloads" index element={<Downloads/>}/>
                <Route path="/collections" index element={<Collections/>}/>
                <Route path="/collections/:id" index element={<Collection/>}/>
                <Route path="/subscriptions" index element={<Subscriptions/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
              </>
            }
            <Route path="*" element={<Navigate to="/"/>}/>
          </Routes>
        </Suspense>
      }

    </ThemeProvider>
  );
}
