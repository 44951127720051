import React, {useContext, useEffect, useState} from "react";
import {Chip, Fade, IconButton, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  IconChevronLeft,
  IconChevronRight, IconDownload,
  IconSearch,
  IconShare,
  IconShoppingCartPlus,
  IconX
} from "@tabler/icons-react";
import Modal from "@mui/material/Modal";
import {grey} from "@mui/material/colors";
import {AddToCollectionButton} from "./components/AddToCollectionButton";
import {DarkSecondaryIconButton, SecondaryIconButton} from "../../components/buttons/CustomIconButton";
import {handleImageDownload, shareImage} from "../../utils/functions";
import checkIsAuth from "../../utils/checkIsAuth";
import {toast} from "react-toastify";
import {Context as AppContext} from "../../context/AppContext";
import {ListImages} from "./components/ListImages";
import {Image} from "../../components/image/Image";
import {IconShoppingCartMinus} from "../../components/icons/IconShoppingCartMinus";
import {SignInContext} from "../../context/SignInContext";
import {useNavigate} from "react-router-dom";
import {CartContext} from "../../context/CartContext";
import {useSwipeable} from "react-swipeable";
import Button from "@mui/material/Button";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vw",
  height: "100&",
  maxHeight: "90vh",
  bgcolor: 'background.paper',
  borderRadius: "0.75rem",
  border: '0px solid #000',
  boxShadow: 24,
  px: 2,
  py: 2,
  overflowY: "auto"
};

export const ImageModal = ({open, handleClose, image, next, prev, onSelect}) => {
  const {state: {userDetails, images, ownedImages}, updateUserDetails} = useContext(AppContext);
  const [shuffleImages, setShuffleImages] = useState([]);
  const {openCartModal} = useContext(CartContext);
  const {openModal} = useContext(SignInContext);
  const handlers = useSwipeable({
    onSwipedLeft: () => onSelect(next),
    onSwipedRight: () => onSelect(prev),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const myDiv = document.getElementById('img-header');
    if (myDiv) {
      myDiv.scrollIntoView({behavior: "smooth"})
    }
    const shuffleArray = (array) => {
      for (let i =  array.length > 10 ? 10 : array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    const shuffled = shuffleArray(images);
    setShuffleImages(shuffled.splice(0, 10));
  }, [image])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "ArrowLeft") {
        onSelect(prev);
      } else if (event.key === "ArrowRight") {
        onSelect(next);
      }
    };

    document.body.addEventListener("keydown", handleKeyPress);

    return () => {
      document.body.removeEventListener("keydown", handleKeyPress);
    };
  }, [onSelect, next, prev]);

  const cart = userDetails?.cart || [];

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => handleClose()}
    >
      <Fade in={open} timeout={500}>
        <Box>
          <Box sx={{position: "fixed", top: 10, right: 10}}>
            <IconButton
              size={"small"}
              onClick={handleClose}
              color={"#FFF"}
            >
              <IconX color={grey[300]}/>
            </IconButton>
          </Box>

          <Box sx={style} className={"scrollbar-hidden"}>
            <Box>
              <ModalHeader
                image={image}
                updateUserDetails={updateUserDetails}
                cart={cart}
                ownedImages={ownedImages}
                userDetails={userDetails}
                openModal={openModal}
                toggleCart={openCartModal}
              />
            </Box>
            <Stack direction={"row"} gap={1} sx={{maxHeight: "70vh", py: 2, position: "relative"}}
                   alignItems={"center"}>
              <Button
                sx={{
                  position: "absolute",
                  top: 0, bottom: 0, left: 0,
                  display: "flex", alignItems: "center", justifyContent: "flex-start",
                  cursor: "pointer", p: 0, m: 0, minWidth: "100px !important", px: 1,
                  "&:hover": {bgcolor: "transparent"}
                }}
                onClick={() => onSelect(prev)}
              >
                <IconChevronLeft size={22} color={"#FFFFFF"} style={{background: 'rgba(0,0,0, 0.15)', padding: "4px", borderRadius: "50%"}}/>
              </Button>
              <Box {...handlers} sx={{flex: 1, height: "100%", mx: "auto", textAlign: "center"}}>
                <Image
                  url={image?.imageUrl}
                  name={image?.name}
                  width={'auto'} height={"100%"}
                  style={{maxHeight: "70vh", minHeight: "400px", maxWidth: "100%"}}
                  loading="lazy"
                />
              </Box>
              <Button
                sx={{
                  position: "absolute",
                  top: 0, bottom: 0, right: 0,
                  display: "flex", alignItems: "center", justifyContent: "flex-end",
                  cursor: "pointer", p: 0, m: 0, minWidth: "100px !important", px: 1,
                  "&:hover": {bgcolor: "transparent"}
                }}
                onClick={() => onSelect(next)}
              >
                <IconChevronRight size={22} color={"#FFFFFF"} style={{background: 'rgba(0,0,0, 0.15)', padding: "4px", borderRadius: "50%"}}/>
              </Button>

            </Stack>
            <Box sx={{py: 2}}>
              <Typography variant={"h5"} fontWeight={"bold"}>Similar keywords</Typography>
              <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"} sx={{pt: 1}}>
                {
                  image?.tags?.map(keyword => (
                    <Chip key={keyword} icon={<IconSearch size={14}/>} sx={{px: 0.5}}
                          label={keyword}
                          variant="outlined"
                          onClick={() => {
                            navigate(`/images?term=${keyword?.toLowerCase()}`)
                            handleClose();
                          }}/>
                  ))
                }
              </Stack>
            </Box>
            <Box sx={{py: 2}}>
              <Typography variant={"h5"} fontWeight={"bold"}>More photos</Typography>
              <Box sx={{pt: 1}}>
                <ListImages
                  images={shuffleImages}
                  cols={3}
                  onSelect={onSelect}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

const ModalHeader = ({cart, image, userDetails, ownedImages, openModal, updateUserDetails, toggleCart}) => {

  const addToCart = () => {
    try {
      const isAuth = checkIsAuth();
      if (isAuth) {
        const items = [...cart];
        items.push(image);
        updateUserDetails({...userDetails, cart: items});
        toggleCart();
        toast.success("The image has been added to your cart.");
      } else {
        openModal();
      }

    } catch (error) {
      console.log(error.message)
      openModal()
    }
  }
  const removeFromCart = () => {
    try {
      const isAuth = checkIsAuth();
      if (isAuth) {
        const items = [...cart].filter(i => i?.uid !== image?.uid);
        updateUserDetails({...userDetails, cart: items});
        toast.success("The image has been removed from your cart.");
      } else {
        openModal();
      }
    } catch (error) {
      console.log(error.message)
      openModal()
    }
  }

  const isItemInCart = cart?.find(i => i?.uid === image?.uid);
  const isOwned = ownedImages?.find(i => i?.uid === image?.uid);

  return (
    <Stack direction={"row"} alignItems={"center"} id={"img-header"}>
      <Box sx={{flex: 1}}>
        <Typography variant={"h4"} fontWeight={"bold"}>
          {image?.name}
        </Typography>
        {/*<Typography variant={"subtitle2"} color={grey[600]}>*/}
        {/*    4000 x 3424px - 285 downloads*/}
        {/*</Typography>*/}
      </Box>
      <AddToCollectionButton
        image={image}
      />
      <DarkSecondaryIconButton
        title={"Share"}
        onClick={() => shareImage(image)}
        selected={false}
      >
        <IconShare size={18}/>
      </DarkSecondaryIconButton>
      {
        isOwned ?
          <SecondaryIconButton
            title={"Download image"}
            onClick={() => handleImageDownload(image)}
            selected={false}
          >
            <IconDownload size={18}/>
          </SecondaryIconButton>
          :
          isItemInCart ?
            <SecondaryIconButton
              title={"Remove from cart"}
              onClick={removeFromCart}
              selected={false}
            >
              <IconShoppingCartMinus size={18}/>
            </SecondaryIconButton>
            :
            <DarkSecondaryIconButton
              title={"Add to cart"}
              onClick={addToCart}
              selected={false}
            >
              <IconShoppingCartPlus size={18}/>
            </DarkSecondaryIconButton>
      }
    </Stack>
  )
}