import React, { useState } from "react";
import { MainLayout } from "../../layouts/main-layout/MainLayout";

import EnterpriseSection from "./../../plasmicComponents/EnterpriseSection";
import EnterpriseSectionArabic from "./../../plasmicComponents/EnterpriseSectionArabic";
import LanguageSwitchDiv from "./../../plasmicComponents/LanguageSwitchDiv";

const Enterprise = () => {
    const [isArabic, setIsArabic] = useState(false);

    return (
        <MainLayout>
            {isArabic ? (
                <EnterpriseSectionArabic
                    switchContainer={
                        <LanguageSwitchDiv
                            onClick={() => setIsArabic(!isArabic)}
                        />
                    }
                />
            ) : (
                <EnterpriseSection
                    switchContainer={
                        <LanguageSwitchDiv
                            onClick={() => setIsArabic(!isArabic)}
                        />
                    }
                />
            )}
        </MainLayout>
    );
};

export default Enterprise;
