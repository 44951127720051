import React, {useContext, useEffect, useState} from "react";
import {
  Avatar,
  Button,
  Stack,
  Toolbar,
  Typography,
  AppBar,
  TextField,
  IconButton,
  Tooltip, Menu, Box, MenuItem, Badge, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Popover
} from "@mui/material";
import logo from "../../../assets/img/logo.png";
import logoWithText from "../../../assets/img/logo-with-text.png";
import {Link, NavLink} from "react-router-dom";
import {IconHeart, IconMenu, IconPhoto, IconPlus, IconShoppingCart} from "@tabler/icons-react";
import {auth} from "../../../config/firebase";
import {signOut} from "firebase/auth";
import {SignInModal} from "../SignInModal";
import {SignInContext} from "../../../context/SignInContext";
import {Context as AppContext} from "../../../context/AppContext";
import {CartDrawer} from "../CartDrawer";
import {deepPurple, grey} from "@mui/material/colors";
import {CreateCollectionForm} from "../../../pages/browse-images/components/CollectionMenu";
import useDebounce from "../../../hooks/useDebounce";
import {HeaderDrawer} from "./HeaderDrawer";
import checkIsAuth from "../../../utils/checkIsAuth";
import {Image} from "../../../components/image/Image";
import {Search} from "../../../components/search/Search";
import {CartContext} from "../../../context/CartContext";
import {useSetAtom} from "jotai";
import {ContactFormAtom} from "../../../atoms";

export const Header = () => {
  const {state: {userDetails}} = useContext(AppContext);
  const {openModal} = useContext(SignInContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const {openCart, openCartModal, closeCartModal} = useContext(CartContext);
  const setContactModalAtom = useSetAtom(ContactFormAtom);

  const cart = userDetails?.cart || [];

  const onOpenCartDrawer = () => {
    try {
      const isAuth = checkIsAuth();
      if (isAuth) {
        openCartModal()
      } else {
        openModal();
      }
    } catch (err) {
      openModal();
    }
  }

  const openContactModal = () => {
    setContactModalAtom({open: true, title: "Request Image", message: ""})
  }


  return (
    <>
      <CartDrawer/>
      <HeaderDrawer
        open={openDrawer}
        handleClose={() => setOpenDrawer(false)}
        openCart={() => {
          openCartModal();
          setOpenDrawer(false);
        }}
      />
      <AppBar position="fixed"
              sx={{
                bgcolor: "#FFF",
                boxShadow: 0
              }}
      >
        <Toolbar
          sx={{
            bgcolor: "transparent",
            my: 0,
            display: {xs: 'none', md: "inherit"}
          }}
        >
          <Stack direction={"row"} sx={{width: "100%"}} gap={2} justifyContent={"space-between"}
                 alignItems={"center"}>

            <Stack direction="row" alignItems="center" gap={2}>
              <Button
                component={Link}
                to={"/"}
                variant={"text"}
                sx={{color: "#000"}}
              >
                <Box sx={{width: {xs: "60px", md: "80px"}}}>
                  <img
                    src={logoWithText}
                    alt={"Logo"}
                    style={{width: "100%"}}
                  />
                </Box>
              </Button>
              <Box sx={{flex: 1}}>
                <Search/>
              </Box>
            </Stack>

            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Button
                component={NavLink}
                to={"/images"}
                className={"nav-link"}
                sx={{
                  textDecoration: 'none',
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: '#000'
                }}
              >
                Browse
              </Button>
              <Button
                component={NavLink}
                to={"/pricing"}
                className={"nav-link"}
                sx={{
                  textDecoration: 'none',
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: '#000'
                }}
              >
                Pricing
              </Button>
              <Button
                component={NavLink}
                to={"/mission"}
                className={"nav-link"}
                sx={{
                  textDecoration: 'none',
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: '#000'
                }}
              >
                Mission
              </Button>
              <Button
                component={NavLink}
                to={"/enterprise"}
                className={"nav-link"}
                sx={{
                  textDecoration: 'none',
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: '#000'
                }}
              >
                Enterprise
              </Button>
              <Button
                onClick={openContactModal}
                className={"nav-link"}
                sx={{
                  textDecoration: 'none',
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: '#000'
                }}
              >
                Request Image
              </Button>
              <Button
                component={NavLink}
                to={"/collections"}
                className={"nav-link"}
                sx={{
                  textDecoration: 'none',
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: '#000',
                  minWidth: 32
                }}
              >
                <IconHeart/>
              </Button>

              {/*<CollectionButton/>*/}

              <Button
                onClick={onOpenCartDrawer}
                className={"nav-link"}
                sx={{
                  textDecoration: 'none',
                  textTransform: "capitalize",
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                  color: '#000',
                  minWidth: 32
                }}
              >
                <Badge badgeContent={cart?.length} color="secondary">
                  <IconShoppingCart sx={{fontSize: "0.75rem"}}/>
                </Badge>
              </Button>

              {
                auth?.currentUser ?
                  <UserAvatar userDetails={userDetails}/>
                  :
                  <Button
                    onClick={() => openModal()}
                    sx={{
                      bgcolor: "transparent",
                      borderRadius: 5,
                      textTransform: "capitalize",
                      fontSize: 14,
                      px: 2,
                      fontWeight: "bold",
                      color: '#4a4a4a',
                      borderColor: "#4a4a4a",
                      border: 1,
                      "&:hover": {
                        color: "#FFF",
                        bgcolor: "#4a4a4a"
                      }
                    }}
                  >
                    Sign in
                  </Button>
              }

            </Stack>
          </Stack>
        </Toolbar>

        <Toolbar
          sx={{
            bgcolor: "transparent",
            my: {xs: 0, md: 1},
            display: {xs: 'inherit', md: "none"}
          }}
        >
          <Stack direction={"row"} sx={{width: "100%"}} gap={1} justifyContent={"space-between"}
                 alignItems={"center"}>
            <Button
              component={Link}
              to={"/"}
              variant={"text"}
              sx={{color: "#000"}}
            >
              <Avatar
                src={logo}
                sx={{borderRadius: 0, width: 30, height: 30}}
              />
              {/*<Typography sx={{ml: 1}} fontSize={18} fontWeight={"bold"}>Sura صورة</Typography>*/}
            </Button>
            <Box sx={{flex: 1}}>
              <Search/>
            </Box>
            <IconButton onClick={() => setOpenDrawer(true)}>
              <IconMenu/>
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  )
}

const CollectionButton = () => {
  const {state: {collections}, createCollection} = useContext(AppContext);
  const {openModal} = useContext(SignInContext);
  const [openCreateInput, setOpenCreateInput] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedCollections, setSearchedCollections] = useState([]);
  const searchDebounced = useDebounce(search, 500);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const searched = collections?.filter(item => item?.name?.toLowerCase().startsWith(searchDebounced?.toLowerCase()));
    setSearchedCollections(searched);
  }, [searchDebounced, collections])

  const handleClick = (event) => {
    try {
      checkIsAuth();
      const isAuth = checkIsAuth();
      if (isAuth) {
        setAnchorEl(event.currentTarget);
      } else {
        openModal();
      }

    } catch (err) {
      openModal();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createNewCollection = async (name) => {
    if (!name) {
      return;
    }
    await createCollection(name, "", null, []);
    setOpenCreateInput(false);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        className={"nav-link"}
        sx={{
          textDecoration: 'none',
          textTransform: "capitalize",
          fontSize: "0.75rem",
          fontWeight: "bold",
          color: '#000'
        }}
      >
        <IconHeart sx={{fontSize: "0.75rem"}}/>
      </Button>
      <Popover
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            bgcolor: "transparent",
            boxShadow: 0
          }
        }}
      >
        <Box sx={{bgcolor: "#FFF", mt: "9px", borderRadius: 5, boxShadow: 3, m: 1}}>
          <Box sx={{px: 1, py: 2}}>
            <Typography sx={{textAlign: "center", fontWeight: 900, pb: 1}}>Collections</Typography>
            <TextField
              placeholder={"Search collection..."}
              size={"small"}
              fullWidth
              sx={{bgcolor: "#FFF"}}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                sx: {
                  borderRadius: "62.5rem",
                  ".Mui-focused fieldset": {borderColor: deepPurple[50]}
                }
              }}
            />
          </Box>
          <List sx={{
            maxHeight: "200px",
            overflowY: "auto",
            bgcolor: "#FFF"
          }}>
            {
              searchedCollections?.map(collection => {
                const src = collection?.images?.length > 0 ? collection?.images[0]?.imageUrl : "";
                const alt = collection?.images?.length > 0 ? collection?.images[0].name : "";
                return <ListItemButton key={collection?.uid || collection?.name} component={Link}
                                       to={`/collections/${collection?.uid}`} sx={{"&:hover": {bgcolor: grey[100]}}}>
                  <ListItemIcon>

                    {src ? <Image
                      url={src}
                      name={alt}
                      width={30} height={30}
                      loading="lazy"
                    /> : <IconPhoto/>}
                  </ListItemIcon>
                  <ListItemText>
                    {collection?.name}
                  </ListItemText>
                </ListItemButton>
              })
            }
            <ListItem>
              <CreateCollectionForm open={openCreateInput} handleSubmit={createNewCollection}
                                    handleClose={() => setOpenCreateInput(false)}/>
            </ListItem>
          </List>
          <Box sx={{borderBottom: 1, borderColor: "divider", borderRadiusTop: "0.75rem"}}>
            <List sx={{py: 0}}>
              <ListItemButton
                onClick={() => setOpenCreateInput(true)}
                sx={{color: deepPurple[400], direction: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}
              >
                <IconPlus size={15} color={deepPurple[400]} sx={{color: deepPurple[400]}}/>
                <Typography sx={{fontWeight: "bold", color: deepPurple[400]}}>
                  Create Collection
                </Typography>
              </ListItemButton>
            </List>
          </Box>
        </Box>

      </Popover>
    </>
  )
}

const UserAvatar = ({userDetails}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutHandle = async () => {
    try {
      await signOut(auth);
      handleCloseUserMenu();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Box sx={{flexGrow: 0}}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <Avatar alt="Remy Sharp" src={auth?.currentUser?.photoURL}/>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{mt: '45px', p: 0, borderRadius: 5}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{sx: {py: 0, borderRadius: 5}}}
        slotProps={{
          paper: {sx: {py: 0, borderRadius: 5, minWidth: 260}}
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack direction={"row"} gap={1} alignItems={"center"}
               sx={{px: 2, py: 1, bgcolor: grey[100], borderBottom: 1, borderColor: "divider"}}>
          <Avatar size={"small"} src={auth?.currentUser?.photoURL}/>
          <Stack direction={"column"}>
            <Typography fontWeight={"bold"}>{auth?.currentUser?.displayName}</Typography>
            <Typography color={grey[500]} fontSize={12} fontWeight={500}>
              Available Photos: {userDetails?.subscription?.numberOfCredits >= 0 ? userDetails?.subscription?.numberOfCredits : "N/A"}
            </Typography>
          </Stack>
        </Stack>
        <MenuItem component={Link} to={"/profile"} sx={{px: 3, py: 1, fontSize: 16}}>
          <Typography textAlign="center">Edit profile</Typography>
        </MenuItem>
        <MenuItem component={Link} to={"/downloads"} sx={{px: 3, py: 1, fontSize: 16}}>
          <Typography textAlign="center">Downloads</Typography>
        </MenuItem>
        <MenuItem component={Link} to={"/collections"} sx={{px: 3, py: 1, fontSize: 16}}>
          <Typography textAlign="center">My collections</Typography>
        </MenuItem>
        <MenuItem component={Link} to={"/subscriptions"} sx={{px: 3, py: 1, fontSize: 16}}>
          <Typography textAlign="center">My subscription</Typography>
        </MenuItem>
        <MenuItem onClick={logoutHandle} sx={{borderTop: 1, borderColor: "divider", px: 3, py: 1, fontSize: 16}}>
          <Typography textAlign="center" color={"secondary"} fontWeight={"bold"}>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
